import classNames from "classnames"
import Block from "@/components/wrappers/block"
import styles from "./paragraph.module.css"
import { getTextEffectClasses } from "@/lib/helpers/utils"

function Paragraph({ block, settings }) {
  const classes = [
    styles.paragraph,
    block.attrs?.theme,
    block.attrs.classList,
    settings?.innerAlign
  ]

  let width = settings?.width ?? "column"
  let gap = "content"

  if (block.attrs?.noStyling) {
    width = gap = null
  }

  return (
    <Block
      block={block}
      width={width}
      gap={gap}
      noStyling={block.attrs.noStyling}
      className={classNames(
        classes,
        getTextEffectClasses(block?.attrs?.effect)
      )}
      innerHtml={block.html}
    />
  )
}

export default Paragraph
